html {
  height: 100%;
  height: -webkit-fill-available;
}

body {
  width: 100%;
  padding: 0;
  letter-spacing: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5 !important;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  min-width: 375px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
