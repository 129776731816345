.header {
  .icon {
    width: 32px;
    height: 32px;
    display: flex;
  }
  .email {
    color: red;
  }
}
